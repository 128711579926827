<template>
    <div class="system-body-main">
        <div class="system-list">
            
            <div class="system-list-body">
                <el-scrollbar>
                    
                    <div class="list-avatar-title">用户</div>
                    <a-spin :spinning="onUserLoading">
                        <div class="ul-avatar-list" style="">
                            <a-empty
                                :image="emptyImage"
                                v-if="listUserSearch.length == 0"
                            />
                            <div
                                class="ul-avatar-list-item"
                                :class="{
                                    current:
                                        currentType == 'user' &&
                                        currentIndex == indexUser,
                                }"
                                v-for="(itemUser, indexUser) in listUserSearch"
                                v-bind:key="itemUser.id"
                                :data-index="indexUser"
                                @click="onSelectItem('user', indexUser)"
                            >
                                <div
                                    class="ul-avatar-list-item-img"
                                    :style="{
                                        backgroundImage:
                                            'url(' + itemUser.avatar + ')',
                                    }"
                                ></div>
                                <div class="ul-avatar-list-item-info">
                                    <div class="ul-avatar-list-item-title">
                                        {{ itemUser.nickname }}
                                    </div>

                                    <div class="ul-avatar-list-item-time"></div>
                                </div>
                            </div>
                        </div>
                    </a-spin>
                </el-scrollbar>
            </div>
        </div>
        <div class="system-panel">
            <div class="system-panel-main">
                <InfoCardComponent
                    v-if="currentItem != null"
                ></InfoCardComponent>
            </div>
        </div>
    </div>
</template>

<script>
import tools from "@/assets/js/tools";
import { Empty } from "ant-design-vue";
import InfoCardComponent from "@/components/InfoCardComponent";
import { mapState } from "vuex";
import store from "@/store/main";

export default {
    components: {
        InfoCardComponent,
    },
    data() {
        return {
            listUserSearch: [],
            listGroupSearch: [],
            onUserLoading: false,
            onGroupLoading: false,

            emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,

            currentIndex: 0,
        };
    },
    computed: {
        ...mapState({
            currentItem: "currentInfoCardItem",
            currentType: "currentInfoCardType",
            searchKeywords:'searchKeywords'
        }),
    },
    created() {},
    methods: {
        onSelectItem(type, index) {
            this.currentIndex = index;
            var currentItem = {};
            if (type == "group") {
                currentItem = this.listGroupSearch[index];
            } else {
                currentItem = this.listUserSearch[index];
                currentItem.title = currentItem.nickname;
                currentItem.uid = currentItem.account;
                currentItem.title_img = currentItem.avatar;
            }

            store.commit("setCurrentInfoCardItem", {
                currentItem,
                type: type,
            });
        },
        onSearch() {
            this.onUserLoading = true;
            this.onGroupLoading = true;
            tools.request(
                "User/searchUser",
                {
                    keywords: this.searchKeywords,
                },
                (result) => {
                    this.onUserLoading = false;
                    this.listUserSearch = result.data;
                }
            );
            tools.request(
                "User/searchGroup",
                {
                    keywords: this.searchKeywords,
                },
                (result) => {
                    this.onGroupLoading = false;
                    this.listGroupSearch = result.data;
                }
            );
        },
    },
};
</script>

<style scoped>
</style>