<template>
    <div class="system-body-main">
        <div class="system-list">
            <div class="system-list-header">设置</div>
            <div class="system-list-body">
                <a-menu
                    id="dddddd"
                    v-model:selectedKeys="selectedKeys"
                    mode="inline"
                >
                    <a-menu-item key="info">
                        <span>基本信息</span>
                    </a-menu-item>
                </a-menu>
            </div>
        </div>
        <div class="system-panel">
            <div class="info-card">
                <a-descriptions
                    title="基本信息"
                    :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }"
                >
                    <a-descriptions-item label="头像">
                        <a-avatar :size="64" :src="userinfo.avatar">
                            <template #icon><UserOutlined /></template>
                        </a-avatar>
                    </a-descriptions-item>
                    <a-descriptions-item label="UID">
                        {{ userinfo.uid }}
                    </a-descriptions-item>
                    <a-descriptions-item label="账号类型">
                        {{ userinfo.auth_info.title }}
                    </a-descriptions-item>
                    <a-descriptions-item label="昵称">{{
                        userinfo.nickname
                    }}</a-descriptions-item>
                    <a-descriptions-item label="账号">
                        {{ userinfo.account }}
                    </a-descriptions-item>
                </a-descriptions>

                <div class="info-options"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    components: {},
    data() {
        return {
            selectedKeys: ["info"],
        };
    },
    computed: {
        ...mapState(["userinfo"]),
    },
};
</script>

<style scoped>
.info-card {
    margin: 30px;
}
</style>