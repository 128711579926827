const messageWaitCall = [];

const message = {
    on(callback) {
        messageWaitCall.push(callback)
    },
    emit(data) {
        messageWaitCall.forEach((value) => {
            value(data)
        })
    },
    clear(){
        messageWaitCall.splice(0)
    }
}

export default message