<template>
    <div class="login-container">
        <a-spin :spinning="isContainerLoading"> </a-spin>
    </div>
</template>

<script>
import tools from "@/assets/js/tools";
import store from "@/store/main";
import { notification } from "ant-design-vue";
export default {
    data() {
        return {
            isContainerLoading: true,
            formState: {
                nickname: "anychat-" + Math.round(Math.random() * 100000000),
            },
        };
    },
    created() {
        var session = tools.storage.get("session");
        store.commit("setSession", session);

        tools.request(
            "Index/myinfo",
            (result, code) => {
                if (code == 700) {
                    store.commit("setUserinfo", null);

                    this.doLogin();
                    return false;
                }
                this.isContainerLoading = false;
                store.commit("setUserinfo", result.data);
            },
            {
                closeLoginStop: true,
            }
        );
    },
    methods: {
        doLogin(intoToken) {
            if (typeof intoToken == 'undefined') {
                intoToken = tools.getUrlParam("into_token");
            }
            console.log(intoToken);

            if (intoToken == null) {
                tools.request(
                    tools.buildTokenUrl,
                    {
                        href: location.href,
                    },
                    (result) => {

                        location.href = result.data;
                    }
                );
            } else {
                tools.request(
                    "Index/login",
                    {
                        into_token: intoToken,
                    },
                    (result) => {

                        if (result.code == 1) {
                            notification.error({
                                message: "登录失败",
                                description: result.msg,
                            });
                            setTimeout(() => {
                                this.doLogin(null);
                            }, 1200);
                            return false;
                        }
                        store.commit("setUserinfo", result.data.user);
                        store.commit("setSession", result.data.session_info);

                        tools.storage.set("session", result.data.session_info);
                    }
                );
            }
        },
    },
};
</script>

<style scoped>
.login-container {
    position: fixed;
    right: 80px;
    bottom: 80px;
    z-index: 999999;
    background-color: #fff;
    display: flex;
    box-shadow: 0 0 1px #bbb;
    text-align: center;
    padding: 25px;
}
</style>