<template>
    <div class="friend-select-container" v-if="show">
        <div class="friend-select-main">
            <div class="container-option">
                <close-outlined
                    class="item close-container"
                    @click="onCancel"
                />
            </div>
            <div class="friend-list-container">
                <div class="item left">
                    <div class="header">
                        <a-input
                            v-model:value="searchKeywords"
                            placeholder="搜索"
                            size="small"
                        >
                            <template #prefix>
                                <search-outlined />
                            </template>
                        </a-input>
                    </div>
                    <div class="body">
                        <div class="ul-avatar-list" style="">
                            <div
                                class="ul-avatar-list-item"
                                v-for="(item, index) in listMyFriend"
                                :key="item.id"
                                @click="onSelectItem(item, index)"
                            >
                                <div
                                    class="ul-avatar-list-item-img"
                                    :style="{
                                        backgroundImage:
                                            'url(' + item.target.avatar + ')',
                                    }"
                                ></div>
                                <div class="ul-avatar-list-item-info">
                                    <div class="ul-avatar-list-item-title">
                                        {{ item.target.nickname }}
                                    </div>
                                </div>
                                <a-checkbox
                                    :checked="
                                        listFriendChecked.findIndex(
                                            (checkedItem) =>
                                                checkedItem.id == item.id
                                        ) >= 0
                                    "
                                    :disabled="
                                        listDisableId.indexOf(item.target_user_id) >= 0
                                    "
                                ></a-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item right">
                    <div class="header">
                        <div v-if="listFriendChecked.length == 0">
                            请勾选需要添加的联系人
                        </div>
                        <div v-else>
                            已选择了{{ listFriendChecked.length }}个联系人
                        </div>
                    </div>
                    <div class="body">
                        <div class="ul-avatar-list" style="">
                            <div
                                class="ul-avatar-list-item"
                                v-for="(item, index) in listFriendChecked"
                                :key="item.id"
                                @click="onSelectItem(item, index)"
                            >
                                <div
                                    class="ul-avatar-list-item-img"
                                    :style="{
                                        backgroundImage:
                                            'url(' + item.target.avatar + ')',
                                    }"
                                ></div>
                                <div class="ul-avatar-list-item-info">
                                    <div class="ul-avatar-list-item-title">
                                        {{ item.target.nickname }}
                                    </div>
                                </div>
                                <close-circle-filled
                                    class="remove-checked-option"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <a-button
                            size="small"
                            type="primary"
                            @click="onConfirm"
                            :disabled="listFriendChecked.length == 0"
                            >确定</a-button
                        >
                        <a-button size="small" @click="onCancel">取消</a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "FriendSelectComponent",

    props: {
        show: {
            type: Boolean,
            default: false,
        },
        listDisableId: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            listFriendChecked: [],
            searchKeywords: "",
        };
    },
    computed: {
        ...mapState(["listMyFriend"]),
    },
    emits: ["update:show", "onConfirm"],
    methods: {
        onCancel() {
            this.$emit("update:show", false);
            this.listFriendChecked = [];
            
        },
        onConfirm() {
            this.$emit("onConfirm", this.listFriendChecked);
            this.$emit("update:show", false);
            this.listFriendChecked = [];
        },
        onSelectItem(item) {

            if(this.listDisableId.indexOf(item.target_user_id) >= 0){
                return false;
            }

            var checkedItemIndex = this.listFriendChecked.findIndex(
                (checkedItem) => checkedItem.id == item.id
            );

            if (checkedItemIndex < 0) {
                this.listFriendChecked.push(item);
            } else {
                this.listFriendChecked.splice(checkedItemIndex, 1);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.friend-select-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.friend-select-main {
    width: 70%;
    height: 80%;
    background: #fff;
    box-shadow: 0 0 3px #bbb;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
}

.container-option {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: right;

    .item {
        font-size: 12px;
        color: #bbb;
        padding: 5px 10px;
        cursor: pointer;
    }
    .right {
        .header {
            margin-top: 20px;
        }
    }
    .close-container:hover {
        color: #fff;
        background-color: rgb(251, 115, 115);
    }
}

.friend-list-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    .item {
        flex: 1;
        flex-shrink: 0;
        padding: 15px;
        height: 100%;
        .header {
            padding: 8px;
        }
        .body {
            margin-top: 10px;
        }

        .footer {
            text-align: right;
            position: absolute;
            right: 15px;
            bottom: 15px;
        }
        .footer > * {
            margin-left: 10px;
        }
    }

    .left {
        border-right: 1px solid #eee;
    }
}

.ul-avatar-list-item {
    background-color: #fff;
    justify-content: flex-start;
}
.ul-avatar-list-item:hover {
    background-color: #eee;
}
.ul-avatar-list-item-img {
    width: 30px;
    height: 30px;
}

.remove-checked-option {
    color: #bbb;
}
</style>