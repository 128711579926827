
var face = {
    "[微笑]": "/static/lib/face/images/1.gif",
    "[撇嘴]": "/static/lib/face/images/2.gif",
    "[色]": "/static/lib/face/images/3.gif",
    "[发呆]": "/static/lib/face/images/4.gif",
    "[得意]": "/static/lib/face/images/5.gif",
    "[流泪]": "/static/lib/face/images/6.gif",
    "[害羞]": "/static/lib/face/images/7.gif",
    "[闭嘴]": "/static/lib/face/images/8.gif",
    "[睡]": "/static/lib/face/images/9.gif",
    "[大哭]": "/static/lib/face/images/10.gif",
    "[尴尬]": "/static/lib/face/images/11.gif",
    "[发怒]": "/static/lib/face/images/12.gif",
    "[调皮]": "/static/lib/face/images/13.gif",
    "[呲牙]": "/static/lib/face/images/14.gif",
    "[惊讶]": "/static/lib/face/images/15.gif",
    "[难过]": "/static/lib/face/images/16.gif",
    "[酷]": "/static/lib/face/images/17.gif",
    "[冷汗]": "/static/lib/face/images/18.gif",
    "[抓狂]": "/static/lib/face/images/19.gif",
    "[吐]": "/static/lib/face/images/20.gif",
    "[偷笑]": "/static/lib/face/images/21.gif",
    "[可爱]": "/static/lib/face/images/22.gif",
    "[白眼]": "/static/lib/face/images/23.gif",
    "[傲慢]": "/static/lib/face/images/24.gif",
    "[饥饿]": "/static/lib/face/images/25.gif",
    "[困]": "/static/lib/face/images/26.gif",
    "[惊恐]": "/static/lib/face/images/27.gif",
    "[流汗]": "/static/lib/face/images/28.gif",
    "[憨笑]": "/static/lib/face/images/29.gif",
    "[大兵]": "/static/lib/face/images/30.gif",
    "[奋斗]": "/static/lib/face/images/31.gif",
    "[咒骂]": "/static/lib/face/images/32.gif",
    "[疑问]": "/static/lib/face/images/33.gif",
    "[嘘]": "/static/lib/face/images/34.gif",
    "[晕]": "/static/lib/face/images/35.gif",
    "[折磨]": "/static/lib/face/images/36.gif",
    "[哀]": "/static/lib/face/images/37.gif",
    "[骷髅]": "/static/lib/face/images/38.gif",
    "[敲打]": "/static/lib/face/images/39.gif",
    "[再见]": "/static/lib/face/images/40.gif",
    "[擦汗]": "/static/lib/face/images/41.gif",
    "[抠鼻]": "/static/lib/face/images/42.gif",
    "[鼓掌]": "/static/lib/face/images/43.gif",
    "[糗大了]": "/static/lib/face/images/44.gif",
    "[坏笑]": "/static/lib/face/images/45.gif",
    "[左哼哼]": "/static/lib/face/images/46.gif",
    "[右哼哼]": "/static/lib/face/images/47.gif",
    "[哈欠]": "/static/lib/face/images/48.gif",
    "[鄙视]": "/static/lib/face/images/49.gif",
    "[委屈]": "/static/lib/face/images/50.gif",
    "[快哭了]": "/static/lib/face/images/51.gif",
    "[阴险]": "/static/lib/face/images/52.gif",
    "[亲亲]": "/static/lib/face/images/53.gif",
    "[吓]": "/static/lib/face/images/54.gif",
    "[可怜]": "/static/lib/face/images/55.gif",
    "[菜刀]": "/static/lib/face/images/56.gif",
    "[西瓜]": "/static/lib/face/images/57.gif",
    "[啤酒]": "/static/lib/face/images/58.gif",
    "[篮球]": "/static/lib/face/images/59.gif",
    "[乒乓]": "/static/lib/face/images/60.gif",
    "[咖啡]": "/static/lib/face/images/61.gif",
    "[饭]": "/static/lib/face/images/62.gif",
    "[猪头]": "/static/lib/face/images/63.gif",
    "[玫瑰]": "/static/lib/face/images/64.gif",
    "[凋谢]": "/static/lib/face/images/65.gif",
    "[示爱]": "/static/lib/face/images/66.gif",
    "[爱心]": "/static/lib/face/images/67.gif",
    "[心碎]": "/static/lib/face/images/68.gif",
    "[蛋糕]": "/static/lib/face/images/69.gif",
    "[闪电]": "/static/lib/face/images/70.gif",
    "[炸弹]": "/static/lib/face/images/71.gif",
    "[刀]": "/static/lib/face/images/72.gif",
    "[足球]": "/static/lib/face/images/73.gif",
    "[瓢虫]": "/static/lib/face/images/74.gif",
    "[便便]": "/static/lib/face/images/75.gif",
    "[月亮]": "/static/lib/face/images/76.gif",
    "[太阳]": "/static/lib/face/images/77.gif",
    "[礼物]": "/static/lib/face/images/78.gif",
    "[拥抱]": "/static/lib/face/images/79.gif",
    "[强]": "/static/lib/face/images/80.gif",
    "[弱]": "/static/lib/face/images/81.gif",
    "[握手]": "/static/lib/face/images/82.gif",
    "[胜利]": "/static/lib/face/images/83.gif",
    "[抱拳]": "/static/lib/face/images/84.gif",
    "[勾引]": "/static/lib/face/images/85.gif",
    "[拳头]": "/static/lib/face/images/86.gif",
    "[差劲]": "/static/lib/face/images/87.gif",
    "[爱你]": "/static/lib/face/images/88.gif",
    "[no]": "/static/lib/face/images/89.gif",
    "[ok]": "/static/lib/face/images/90.gif",
    "[爱情]": "/static/lib/face/images/91.gif",
    "[飞吻]": "/static/lib/face/images/92.gif",
    "[跳跳]": "/static/lib/face/images/93.gif",
    "[发抖]": "/static/lib/face/images/94.gif",
    "[怄火]": "/static/lib/face/images/95.gif",
    "[转圈]": "/static/lib/face/images/96.gif",
    "[磕头]": "/static/lib/face/images/97.gif",
    "[回头]": "/static/lib/face/images/98.gif",
    "[跳绳]": "/static/lib/face/images/99.gif",
    "[挥手]": "/static/lib/face/images/100.gif",
    "[跳高]": "/static/lib/face/images/101.gif",
    "[吼叫]": "/static/lib/face/images/102.gif",
    "[练武]": "/static/lib/face/images/103.gif",
    "[左摆]": "/static/lib/face/images/104.gif",
    "[右摆]": "/static/lib/face/images/105.gif",
    "[囍]": "/static/lib/face/images/106.gif",
    "[鞭炮]": "/static/lib/face/images/107.gif",
    "[灯笼]": "/static/lib/face/images/108.gif",
    "[发]": "/static/lib/face/images/109.gif",
    "[麦克风]": "/static/lib/face/images/110.gif",
    "[手袋]": "/static/lib/face/images/111.gif",
    "[信]": "/static/lib/face/images/112.gif",
    "[帅]": "/static/lib/face/images/113.gif",
    "[庆祝]": "/static/lib/face/images/114.gif",
    "[蜡烛]": "/static/lib/face/images/115.gif",
    "[生气]": "/static/lib/face/images/116.gif",
    "[棒棒糖]": "/static/lib/face/images/117.gif",
    "[奶瓶]": "/static/lib/face/images/118.gif",
    "[面条]": "/static/lib/face/images/119.gif",
    "[香蕉]": "/static/lib/face/images/120.gif",
    "[飞机]": "/static/lib/face/images/121.gif",
    "[汽车]": "/static/lib/face/images/122.gif",
    "[车头]": "/static/lib/face/images/123.gif",
    "[车厢]": "/static/lib/face/images/124.gif",
    "[车尾]": "/static/lib/face/images/125.gif",
    "[多云]": "/static/lib/face/images/126.gif",
    "[下雨]": "/static/lib/face/images/127.gif",
    "[钞票]": "/static/lib/face/images/128.gif",
    "[熊猫]": "/static/lib/face/images/129.gif",
    "[灯泡]": "/static/lib/face/images/130.gif",
    "[风车]": "/static/lib/face/images/131.gif",
    "[闹钟]": "/static/lib/face/images/132.gif",
    "[雨伞]": "/static/lib/face/images/133.gif",
    "[气球]": "/static/lib/face/images/134.gif",
    "[钻戒]": "/static/lib/face/images/135.gif",
    "[沙发]": "/static/lib/face/images/136.gif",
    "[厕纸]": "/static/lib/face/images/137.gif",
    "[药丸]": "/static/lib/face/images/138.gif",
    "[手枪]": "/static/lib/face/images/139.gif",
    "[青蛙]": "/static/lib/face/images/140.gif"

}


export default face;