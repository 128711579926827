import store from "@/store/main";
import axios from "axios";
import storage from "@/assets/js/tools/sotrage"
import Qs from "qs"
import { notification } from "ant-design-vue";
import message from "@/assets/js/tools/message";

axios.defaults.withCredentials = true

const baseHost = process.env.VUE_APP_BASE_HOST;
const wssHost = process.env.VUE_APP_WS_HOST;
const buildTokenUrl = process.env.VUE_APP_BUILD_TOKEN_URL;

const request = function (url, data, success, setting) {



    if (url.indexOf(0) != '/') {

        // 链接的是否以http开头
        if (url.indexOf('http') != 0) {
            url = baseHost + '/api/' + url;
        }

    } else {
        // 以/开头
        url = baseHost + url;
    }

    if (typeof data == 'function') {
        setting = success;
        success = data;
        data = {};
    }

    if (typeof success != 'function') {
        success = function () { }
    }

    if (typeof setting == 'undefined') {
        setting = {}
    }

    if (typeof setting.closeLoginStop == 'undefined') {
        setting.closeLoginStop = false;
    }

    if (typeof setting.closeNormalErrorTips == 'undefined') {
        setting.closeNormalErrorTips = false;
    }

    var header = {}
    if (store.state.session != null) {
        var sessionId = store.state.session.session_id;
        header['session-id'] = sessionId;
    }

    // header['Content-Type'] = 'multipart/form-data';

    axios.post(url, data, {
        transformRequest: [function (data) {
            let ret = ''
            ret = Qs.stringify(data)
            return ret
        }],
        headers: header

    }).then(function (response) {
        if (!setting.closeLoginStop) {
            if (response.data.code == 700) {

                store.commit('setUserinfo', null);
                return false;
            }
        }
        if (!setting.closeNormalErrorTips) {
            if (response.data.code == 500) {
                notification['error']({
                    message: '访问错误',
                    description: response.data.msg
                })
            }
        }

        success(response.data, response.data.code)
    })

}

const uploadFile = function (file, type, success) {
    var url = baseHost + '/api/Files/save'

    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type);


    var header = {}
    if (store.state.session != null) {
        var sessionId = store.state.session.session_id;
        header['session-id'] = sessionId;
    }

    header['Content-Type'] = 'multipart/form-data';


    axios.post(url, formData, {

        headers: header

    }).then(function (response) {

        success(response.data, response.data.code)
    })
}

// 解析链接的参数
const getUrlParam = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null) return unescape(r[2]); return null; //返回参数值
}

const tools = {
    request,
    uploadFile,
    storage,
    baseHost,
    wssHost,
    message,
    buildTokenUrl,
    getUrlParam,
}

export default tools