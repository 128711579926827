<template>
    <a :href="item.content" target="_blank" class="chat-chat-file">
        <div class="main">
            <div class="record-file-name">
                {{ item.ext_data.file_name }}
            </div>
            <div class="record-file-icon" :class="fileType">
                <file-excel-filled v-if="'excel' == fileType" />
                <file-pdf-filled v-if="'pdf' == fileType" />
                <file-ppt-filled v-if="'ppt' == fileType" />
                <file-word-filled v-if="'word' == fileType" />
                <file-zip-filled v-if="'zip' == fileType" />
                <file-unknown-filled v-if="'file' == fileType" />
            </div>
        </div>
        <div class="footer">
            {{ item.ext_data.file_size }}
        </div>
    </a>
</template>

<script>
export default {
    name: "ChatRecordFileComponent",
    data() {
        return {
            fileType: "file",
        };
    },
    props: {
        item: Object,
    },

    created() {

        const fileTypeWithExtName = {
            xlsx: "excel",
            xls: "excel",
            docx: "word",
            doc: "word",
            zip: "zip",
            pdf: "pdf",
            pptx: "ppt",
            ppt: "ppt",
        };

        if (fileTypeWithExtName[this.item.ext_data.ext_name]) {
            this.fileType = fileTypeWithExtName[this.item.ext_data.ext_name];
        }
    },
};
</script>

<style lang="scss" scoped>
.chat-chat-file {
    background-color: #fff;
    display: block;
    width: 240px;
    color: #000 !important;
    cursor: pointer;

    .main {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px 15px 5px 15px;
        height: 70px;

        .record-file-name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 160px;
        }
        .record-file-icon {
            font-size: 40px;

            &.file {
                color: #bbb;
            }
            &.excel {
                color: rgb(32, 115, 70);
            }
            &.zip {
                color: rgb(139, 87, 42);
            }
            &.word {
                color: rgb(44, 86, 154);
            }
            &.ppt {
                color: rgb(242, 97, 63);
            }
            &.pdf {
                color: rgb(250, 81, 81);
            }
        }
    }
    .footer {
        font-size: 12px;
        color: #999;
        border-top: 1px solid #eee;
        padding: 5px 15px 5px 15px;
    }
}
</style>