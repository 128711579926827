import store from "@/store/main";

const storage = {

    buildKey(key) {
        key = store.state.appBind + '-' + key;

        return key;
    },

    set(key, value) {

        key = this.buildKey(key);

        value = [value];

        localStorage.setItem(key, JSON.stringify(value))

        return value;
    },
    get(key, defaultValue) {


        key = this.buildKey(key);

        var value = localStorage.getItem(key);

        if (!value) {
            return defaultValue;
        }

        return JSON.parse(value)[0];
    }
}

export default storage;